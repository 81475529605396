/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ShopPatient from './ShopPatient';
import ShopSort from './ShopSort';

function ShopFilters({
  priceBookProducts = [],
  filters,
  setFilters,
  setFilteredProducts,
  filteredProducts = [],
}) {
  useEffect(() => {
    if (priceBookProducts.length > 0) {
      const mappedCategories = priceBookProducts.map((priceBookProduct) => {
        const { productCategories } = priceBookProduct.product;

        return productCategories.map((productCategory) => ({
          ...productCategory,
          checked: false,
        }));
      });

      const mappedTypes = priceBookProducts.map((priceBookProduct) => ({
        name: priceBookProduct.product.productType,
        checked: false,
      }));

      setFilters({
        types: mappedTypes,
        categories: [...new Set(mappedCategories.flat(1))].sort((a, b) =>
          a.name.localeCompare(b.name),
        ),
      });
    }
  }, [priceBookProducts, setFilters]);

  useEffect(() => {
    const selectedCategories = filters.categories
      .filter((category) => category.checked)
      .map((category) => category.id);

    const filteredProds = priceBookProducts.filter((priceBookProduct) => {
      const { product } = priceBookProduct;

      return (
        selectedCategories.length === 0 ||
        product.productCategories.some((category) =>
          selectedCategories.includes(category.id),
        )
      );
    });

    setFilteredProducts(filteredProds);
  }, [filters.categories, priceBookProducts, setFilteredProducts]);

  function clearFilters() {
    setFilters({
      types: filters.types.map((type) => ({ ...type, checked: false })),
      categories: filters.categories.map((category) => ({
        ...category,
        checked: false,
      })),
    });
  }

  function handleCategoryChange(event) {
    const { value, checked } = event.target;

    setFilters({
      ...filters,
      categories: filters.categories.map((category) =>
        category.id === value ? { ...category, checked } : category,
      ),
    });
  }

  return (
    <Disclosure
      as="section"
      aria-labelledby="filter-heading"
      className="bg-rcgray-100 relative mb-4 grid grid-cols-2 items-center gap-2 border-y border-gray-200 sm:grid-cols-3 sm:gap-4"
    >
      <div className="relative col-span-1 py-2 sm:py-4">
        <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
          <div>
            <Disclosure.Button className="group flex items-center font-medium text-gray-700">
              <FunnelIcon
                className="mr-2 size-5 flex-none text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              {filters.categories.filter((cat) => cat.checked).length} Filters
            </Disclosure.Button>
          </div>
          <div className="pl-6">
            <button
              type="button"
              className="text-gray-500"
              onClick={clearFilters}
            >
              Clear all
            </button>
          </div>
        </div>
      </div>
      <Disclosure.Panel className="absolute top-[80px] z-[99] rounded-lg border border-gray-200 bg-white py-10 shadow-lg sm:top-[52px]">
        <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
          <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
            <fieldset>
              <legend className="block font-medium">Category</legend>
              <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                {filters.categories.map((category) => (
                  <div
                    key={category.id}
                    className="flex items-center text-base sm:text-sm"
                  >
                    <input
                      id={`category-${category.id}`}
                      name="category[]"
                      onChange={handleCategoryChange}
                      type="checkbox"
                      checked={category.checked}
                      value={category.id}
                      className="text-rcprimary-400 focus:ring-rcprimary-400 disabled:bg-rcgray-600 disabled:ring-rcgray-700 disabled:text-rcgray-600 border-rcgray-600 size-4 rounded disabled:cursor-not-allowed"
                    />
                    <label
                      htmlFor={`category-${category.id}`}
                      className="ml-3 min-w-0 flex-1 text-gray-600"
                    >
                      {category.name}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>
      </Disclosure.Panel>

      <ShopPatient />

      <ShopSort
        filteredProducts={filteredProducts}
        setFilteredProducts={setFilteredProducts}
      />
    </Disclosure>
  );
}

ShopFilters.propTypes = {
  priceBookProducts: PropTypes.array,
  filteredProducts: PropTypes.array,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setFilteredProducts: PropTypes.func.isRequired,
};

export default ShopFilters;
