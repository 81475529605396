import PropTypes from 'prop-types';

function DashboardLayoutTitle({ heading, title, subtitle = null }) {
  return (
    <div className="z-10 mx-auto w-full max-w-full px-4 md:max-w-5xl">
      <h2 className="font-oswald text-xl font-bold uppercase text-black">
        {heading}
      </h2>
      <div className="my-2 w-1/4 border-b-4 border-black py-1" />
      <h1 className="mt-2 text-5xl font-bold text-white">{title}</h1>
      {subtitle && (
        <p className="mt-2 text-lg font-normal leading-7 text-white/90 drop-shadow-2xl">
          {subtitle}
        </p>
      )}
    </div>
  );
}

DashboardLayoutTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  heading: PropTypes.string.isRequired,
};

export default DashboardLayoutTitle;
