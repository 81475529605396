import { RcLink, trackingUrl } from '@retainerclub/common';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

function OrderHistoryTrackingInfo({ productionOrders = null }) {
  if (!productionOrders) {
    return null;
  }

  return productionOrders.map((productionOrder) => (
    <Fragment key={productionOrder.id}>
      {productionOrder.productionOrderItems.some((x) => x.trackingNumber) ? (
        <p className="mt-2 text-sm font-medium text-gray-500">
          Tracking Info:
          <br />
          {productionOrder.productionOrderItems.map((item) => (
            <span key={item.id} className="text-sm">
              <RcLink
                href={trackingUrl(
                  item?.shippingCarrier?.name,
                  item.trackingNumber,
                )}
                target="_blank"
                rel="noreferrer"
              >
                {item.trackingNumber}
              </RcLink>{' '}
              {item.shippingCarrier ? `(${item.shippingCarrier.name})` : ''}
            </span>
          ))}
        </p>
      ) : null}
    </Fragment>
  ));
}

OrderHistoryTrackingInfo.propTypes = {
  productionOrders: PropTypes.array,
};

export default OrderHistoryTrackingInfo;
