import { useReferral } from '@retainerclub/hooks';
import { Outlet } from 'react-router-dom';

function OnboardingLayout() {
  const { referral } = useReferral();
  const { partnerLocation } = useReferral();

  return (
    <>
      <div className="from-rcprimary-200 to-rcprimary-500 fixed z-0 h-[500px] w-full bg-gradient-to-t bg-cover bg-top" />
      <div className="z-1 fixed -right-56 hidden h-[500px] w-full bg-[url('https://cdn.retainerclub.com/images/defaults/treatment-form.webp')] bg-right-top bg-no-repeat lg:block" />

      <div className="mx-auto max-w-7xl pt-4 xl:mt-4">
        <Outlet />
        <div className="mx-auto max-w-full md:max-w-4xl">
          <img
            src={
              referral
                ? referral?.partnerLocation?.imageUrl
                : partnerLocation?.imageUrl
            }
            alt={
              referral ? referral?.partnerLocation?.name : partnerLocation?.name
            }
            className="z-50 ml-8 mt-8 size-28"
          />
        </div>
      </div>
    </>
  );
}

export default OnboardingLayout;
