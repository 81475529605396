import { PlusIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function DashboardPatientAddAFamilyMember({ handleOpenModal }) {
  return (
    <div className="basis-1 sm:basis-1/2 md:basis-1/3 ">
      <div className="bg-rcprimary-400/90 border-rcgray-200 hover:bg-rcprimary-400 group relative m-2 flex h-full cursor-pointer flex-col items-center justify-center rounded-lg border-4 border-dashed text-center shadow-lg hover:border-white">
        <button type="button" onClick={() => handleOpenModal(null)}>
          <span aria-hidden="true" className="absolute inset-0" />
          <PlusIcon
            className="text-rcgray-200 mx-auto size-24 drop-shadow-xl group-hover:text-white"
            aria-hidden="true"
          />
          <h3 className="text-rcgray-200 mb-3 text-2xl font-bold drop-shadow-xl group-hover:text-white">
            Add A New Family Member
          </h3>
        </button>
      </div>
    </div>
  );
}

DashboardPatientAddAFamilyMember.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default DashboardPatientAddAFamilyMember;
