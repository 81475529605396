import CheckoutSuccessMessage from './CheckoutSuccessMessage';
import CheckoutSuccessSummary from './CheckoutSuccessSummary';

function CheckoutSuccess() {
  return (
    <div className="relative mb-24">
      <div className="from-rcprimary-200 to-rcprimary-500 fixed z-0 h-[500px] w-full overflow-hidden bg-gradient-to-t bg-cover bg-top" />

      <div className="relative col-span-full mx-auto flex max-w-7xl flex-col gap-y-4 sm:pt-4">
        <div className="bg-gray-50 sm:mt-8 sm:rounded-lg">
          <div className="mx-auto max-w-2xl px-4 pb-24 pt-8 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
              <CheckoutSuccessMessage />

              <CheckoutSuccessSummary />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutSuccess;
