import {
  AccountAddressModal,
  Button,
  DestroyShippingAddress,
} from '@retainerclub/common';
import { CustomerCard } from '@retainerclub/customer-shared';
import { useAccount } from '@retainerclub/hooks';
import { useState } from 'react';
import DashboardLayoutTitle from '../../layouts/DashboardLayout/DashboardLayoutTitle';

function Addresses() {
  const { accountId, accountAddresses, accountRefetch } = useAccount();
  const [currentAddress, setCurrentAddress] = useState(null);
  const [open, setOpen] = useState(false);

  function handleOpenModal(referral) {
    setCurrentAddress(referral);
    setOpen(true);
  }

  function handleCloseModal() {
    setCurrentAddress(null);
    setOpen(false);

    accountRefetch();
  }

  return (
    accountAddresses &&
    accountId && (
      <>
        <DashboardLayoutTitle
          heading="Shipping & Billing Addresses"
          title="My Address Book"
          subtitle="Manage or add shipping and billing addresses to receive your orders."
        />
        <CustomerCard title="My Addresses">
          <ul className="flex flex-col gap-4">
            {accountAddresses &&
              accountAddresses
                .filter((address) => address.isShipping)
                .map((address) => (
                  <li
                    key={address.id}
                    className="flex items-center justify-between border-b pb-4"
                  >
                    <div className="min-w-0 flex-auto">
                      <p className="font-base text-lg leading-6 text-black/80">
                        {address.address}
                        {address.address2 && (
                          <>
                            <br />
                            {address.address2}
                          </>
                        )}
                        <br />
                        <span>
                          {address.city}, {address.region} {address.postalCode}
                        </span>
                        <br />
                        <span>{address.country}</span>
                      </p>
                      {address.isDefault && (
                        <div className="mt-1 flex items-center gap-x-1">
                          <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                            <div className="size-2 rounded-full bg-current" />
                          </div>
                          <p className="text-sm text-black/60">default</p>
                        </div>
                      )}
                    </div>

                    <div className="flex gap-x-2">
                      {!address.isDefault && (
                        <DestroyShippingAddress
                          id={address.id}
                          refetch={accountRefetch}
                        />
                      )}
                      <Button
                        onClick={() => handleOpenModal(address)}
                        size="xs"
                        rounded
                        name="edit"
                        color="gray"
                        edit
                      >
                        Edit
                      </Button>
                    </div>
                  </li>
                ))}
          </ul>
          <div className="mt-4">
            <Button
              onClick={() => handleOpenModal(null)}
              name="Add New Address"
              add
              className="rounded-md"
              size="xs"
            />
          </div>
          {accountId && (
            <AccountAddressModal
              open={open}
              handleCloseModal={() => handleCloseModal()}
              currentAddress={currentAddress}
              accountId={accountId}
            />
          )}
        </CustomerCard>
      </>
    )
  );
}

export default Addresses;
