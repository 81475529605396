function dobFormatter(value, locale = 'en-us') {
  if (!value) return '';
  return new Date(`${value}T00:00:00`).toLocaleDateString(locale, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
}

export default dobFormatter;
