import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

function OnboardingFormStep({ children, step, description, show, title }) {
  return (
    <Transition
      appear
      show={show}
      enter="transition-opacity duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="flex flex-col gap-y-8"
    >
      <div className="border-b-rcprimary-400/50 border-b pb-3">
        <div className="flex flex-col gap-x-4 sm:flex-row">
          <div className="shrink-0">
            <h2 className="text-rcprimary-400 text-3xl font-bold">
              Step {step}
            </h2>
          </div>
          <div className="grow">
            <h3 className="text-rcprimary-400 text-xl font-medium">{title}</h3>
            <p className="text-base text-black/60">{description}</p>
          </div>
        </div>
      </div>
      {children}
    </Transition>
  );
}

OnboardingFormStep.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default OnboardingFormStep;
