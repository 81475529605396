import { LoadingOrError } from '@retainerclub/common';
import { useReferral } from '@retainerclub/hooks';
import OnboardingLayout from '../../layouts/OnboardingLayout/OnboardingLayout';

function Onboarding() {
  const { referral, partnerLocation, rLoading, rError, pLoading, pError } =
    useReferral();

  if (rLoading || rError || pLoading || pError)
    return (
      <LoadingOrError
        error={rError}
        loading={rLoading || pLoading}
        title="Your Infomation"
      />
    );

  return referral || partnerLocation ? (
    <OnboardingLayout />
  ) : (
    <div className="mt-20">
      <h1 className="text-center text-lg">
        Sorry We couldn&apos;t find your infomation.
        <br />
        Are you sure you have the correct link?
        <br />
        <a
          href="https://retainerclub.com"
          className="hover:text-rcprimary-400 text-gray-9 text-sm font-normal leading-6 hover:underline"
        >
          Back to retainerclub.com
        </a>
      </h1>
    </div>
  );
}

export default Onboarding;
