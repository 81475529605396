import PropTypes from 'prop-types';

function CheckoutFormSection({ title, children, first = false }) {
  return (
    <div className={first ? '' : 'border-rcgray-300 mt-4 border-t pt-4'}>
      <h2 className="text-lg font-medium text-gray-900">{title}</h2>

      <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-6">
        {children}
      </div>
    </div>
  );
}

CheckoutFormSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  first: PropTypes.bool,
};

export default CheckoutFormSection;
