import { LanguageSelectAuth } from '@retainerclub/common';
import { useAuth } from '@retainerclub/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function AuthLayout() {
  const location = useLocation();
  const { currentUser } = useAuth();
  const { t } = useTranslation('forms');

  function title() {
    if (location.pathname === '/new-password') {
      return 'Create A New Password';
    }

    if (location.pathname === '/forgot-password') {
      return 'Forgot Password';
    }

    if (location.pathname === '/reset-password') {
      return 'Reset Password';
    }

    return 'Sign In';
  }

  const from = location.state?.from?.pathname || '/';

  return currentUser === null || currentUser === undefined ? (
    <div className="mt-[100px] flex min-h-full flex-col items-center justify-center">
      <div className="flex flex-1 flex-col justify-center rounded-lg border bg-white px-4 py-12 shadow-lg sm:px-6 lg:flex-none lg:px-16 xl:px-20">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-4 text-center text-4xl font-bold tracking-wide text-black">
              {t(title())}
            </h2>
          </div>

          <div className="mt-12">
            <Outlet />
          </div>
          <div className="mt-8 flex justify-center">
            <LanguageSelectAuth />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to={from} replace />
  );
}

export default AuthLayout;
